/* TODO:Branding */
.EmojiPickerReact .epr-category-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: var(--epr-header-padding);
  @apply bg-surface-mapping-secondary-base dark:bg-[#0C0C0C];
  height: 44px;
  align-items: center;
  border-radius: 8px;
  padding-top: 5px;
}

.EmojiPickerReact .epr-category-nav > button.epr-cat-btn {
  display: inline-block;
  transition: opacity 0.2s ease-in-out;
  position: relative;
  height: var(--epr-category-navigation-button-size);
  width: var(--epr-category-navigation-button-size);
  background-size: calc(var(--epr-category-navigation-button-size) * 10);
  outline: none;
  color: var(--epr-category-icon-color);
}

/**.EmojiPickerReact .epr-category-nav > button.epr-cat-btn:focus:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid var(--epr-category-icon-active-color);
  border-radius: 50%;
}**/

aside.EmojiPickerReact.epr-main:has(input:not(:placeholder-shown))
  .epr-category-nav {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.EmojiPickerReact.epr-search-active .epr-category-nav {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
aside.EmojiPickerReact.epr-main:has(input:not(:placeholder-shown))
  .epr-category-nav {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
.EmojiPickerReact.epr-search-active .epr-category-nav {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
aside.EmojiPickerReact.epr-main:has(input:not(:placeholder-shown))
  .epr-category-nav
  > button.epr-cat-btn:hover {
  opacity: 1;
  background-position-y: var(--epr-category-navigation-button-size);
}
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover,
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn.epr-active {
  opacity: 1;
  background-position-y: var(--epr-category-navigation-button-size);
}
.EmojiPickerReact .epr-category-nav > button.epr-cat-btn {
  background-image: url(./svg/CategoryNav.svg);
  background-position: 0 0;
}

.EmojiPickerReact.epr-dark-theme .epr-category-nav > button.epr-cat-btn {
  background-position-y: calc(var(--epr-category-navigation-button-size) * 2);
}

aside.EmojiPickerReact.epr-main:has(input:placeholder-shown) .epr-category-nav {
  background-position-y: calc(var(--epr-category-navigation-button-size) * 3);
}
/* .EmojiPickerReact.epr-dark-theme:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover,
.EmojiPickerReact.epr-dark-theme:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn.epr-active {
  //background-position-y: calc(var(--epr-category-navigation-button-size) * 3);
} */

.EmojiPickerReact .epr-category-nav button.epr-cat-btn svg path {
  transition: all 0.3s ease-in-out;
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover
  .picker-cat-nav-btn-icon.heroicon,
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button:not([aria-label='Smileys & People']).epr-cat-btn.epr-active
  .picker-cat-nav-btn-icon.heroicon {
  /* stroke: #8c8cff; */
  color: #8c8cff;
}

@media (prefers-color-scheme: dark) {
  /* .EmojiPickerReact.epr-auto-theme .epr-category-nav > button.epr-cat-btn {
    background-position-y: calc(var(--epr-category-navigation-button-size) * 2);
  }
  .EmojiPickerReact.epr-auto-theme:not(.epr-search-active)
    .epr-category-nav
    > button.epr-cat-btn:hover,
  .EmojiPickerReact.epr-auto-theme:not(.epr-search-active)
    .epr-category-nav
    > button.epr-cat-btn.epr-active {
    background-position-y: calc(var(--epr-category-navigation-button-size) * 3);
  } */

  .EmojiPickerReact.epr-dark-theme:not(.epr-search-active)
    .epr-category-nav
    > button.epr-cat-btn:hover,
  .EmojiPickerReact.epr-dark-theme:not(.epr-search-active)
    .epr-category-nav
    > button.epr-cat-btn.epr-active
    svg {
    /* stroke: #8c8cff; */
  }
}

/* .picker-cat-nav-btn-icon.heroicon {
  color: #8c8cff;
} */

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn
  .picker-cat-nav-btn-icon.custom-svg.smile
  path {
  fill: var(--epr-category-icon-color);
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover
  .picker-cat-nav-btn-icon.custom-svg.smile
  path,
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn.epr-active
  .picker-cat-nav-btn-icon.custom-svg.smile
  path {
  fill: #8c8cff;
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn
  .picker-cat-nav-btn-icon.custom-svg.activities
  path {
  fill: var(--epr-category-icon-color);
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover
  .picker-cat-nav-btn-icon.custom-svg.activities
  path,
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn.epr-active
  .picker-cat-nav-btn-icon.custom-svg.activities
  path {
  fill: #8c8cff;
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn
  .picker-cat-nav-btn-icon.custom-svg.nature
  path {
  stroke: var(--epr-category-icon-color);
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover
  .picker-cat-nav-btn-icon.custom-svg.nature
  path,
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn.epr-active
  .picker-cat-nav-btn-icon.custom-svg.nature
  path {
  stroke: #8c8cff;
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn
  .picker-cat-nav-btn-icon.custom-svg.food
  path {
  stroke: var(--epr-category-icon-color);
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover
  .picker-cat-nav-btn-icon.custom-svg.food
  path,
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn.epr-active
  .picker-cat-nav-btn-icon.custom-svg.food
  path {
  stroke: #8c8cff;
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn
  .picker-cat-nav-btn-icon.custom-svg.travel
  path {
  stroke: var(--epr-category-icon-color);
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover
  .picker-cat-nav-btn-icon.custom-svg.travel
  path,
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn.epr-active
  .picker-cat-nav-btn-icon.custom-svg.travel
  path {
  stroke: #8c8cff;
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn
  .picker-cat-nav-btn-icon.custom-svg.symbols
  path {
  fill: var(--epr-category-icon-color);
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover
  .picker-cat-nav-btn-icon.custom-svg.symbols
  path,
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn.epr-active
  .picker-cat-nav-btn-icon.custom-svg.symbols
  path {
  fill: #8c8cff;
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn
  .picker-cat-nav-btn-icon.custom-svg.custom
  path {
  fill: var(--epr-category-icon-color);
}

.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  > button.epr-cat-btn:hover
  .picker-cat-nav-btn-icon.custom-svg.custom
  path,
.EmojiPickerReact:not(.epr-search-active)
  .epr-category-nav
  button.epr-cat-btn.epr-active
  .picker-cat-nav-btn-icon.custom-svg.custom
  path {
  fill: #8c8cff;
}

.EmojiPickerReact button.epr-cat-btn.epr-icn-suggested {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -8);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-custom {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -9);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-activities {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -4);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-animals_nature {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -1);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-flags {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -7);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-food_drink {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -2);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-objects {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -5);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-smileys_people {
  background-position-x: 0px;
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-symbols {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -6);
}
.EmojiPickerReact button.epr-cat-btn.epr-icn-travel_places {
  background-position-x: calc(var(--epr-category-navigation-button-size) * -3);
}

.EmojiPickerReact button.epr-cat-btn.epr-icn-none {
  background: none;
}
